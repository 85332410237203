import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { useI18next } from "@herob191/gatsby-plugin-react-i18next"
import { buttonize, useHasMounted, debounce } from "../utils"

export const findNearbySlides = slides => {
  let previous, current, next, index, currentIndex
  previous = current = next = null
  index = currentIndex = 0
  for (const slide of slides) {
    index++
    if (window.scrollY + 10 >= slide.offsetTop) {
      previous = current
      current = slide
      currentIndex = index
      continue
    }
    next = slide
    break
  }
  return {
    previous: previous,
    current: current,
    currentIndex: currentIndex,
    next: next,
  }
}

export const NavigationWrapper = ({ children, pageContext }) => {
  const { t } = useI18next()
  const [hasClickedArrow, setHasClickedArrow] = useState(false)
  const [moreSlides, setMoreSlides] = useState(true)
  const [didInitialSlideCheck, setDidInitialSlideCheck] = useState(false)

  const slides = document.getElementsByClassName("slide")

  const onScroll = () => {
    const { next } = findNearbySlides(slides)
    setMoreSlides(next !== null)
  }

  useEffect(() => {
    const debouncedOnScroll = debounce(onScroll, 50)
    window.addEventListener("scroll", debouncedOnScroll)
    return () => {
      window.removeEventListener("scroll", debouncedOnScroll)
    }
  })

  const keyDownHandler = ({ key, view }) => {
    if (key === "ArrowDown") {
      if (moreSlides) {
        nextSlide()
        view.event.preventDefault()
      }
    }
    if (key === "ArrowUp") {
      previousSlide()
      view.event.preventDefault()
    }
    if (key === "ArrowRight") {
      if (!moreSlides && moreChapters) {
        nextSlide()
      }
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", keyDownHandler)

    return () => {
      window.removeEventListener("keydown", keyDownHandler)
    }
  })

  const hasMounted = useHasMounted()
  if (!hasMounted) {
    return children
  }

  if (!didInitialSlideCheck) {
    onScroll()
    setDidInitialSlideCheck(true)
  }

  const nextSlide = () => {
    setHasClickedArrow(true)
    if (!moreSlides && moreChapters) {
      navigate(pageContext.nextChapterPath)
      return
    }
    const { next } = findNearbySlides(slides)
    if (!next) {
      return
    }
    next.scrollIntoView({ behavior: "smooth" })
  }

  const previousSlide = () => {
    setHasClickedArrow(true)
    const { previous } = findNearbySlides(slides)
    if (!previous) {
      return
    }
    previous.scrollIntoView({ behavior: "smooth" })
  }

  const moreChapters =
    pageContext.nextChapterPath !== null && pageContext.showNextChapterButton
  if (!moreSlides && !moreChapters) {
    return children
  }

  return (
    <>
      {children}
      <nav
        aria-hidden="true"
        className={`slide-nav ${!moreSlides ? "next-chapter" : ""}`}
      >
        {moreSlides && !hasClickedArrow && (
          <span className="arrow-text left">{t("Klikk for å")}</span>
        )}
        {!moreSlides && <span className="arrow-text left">{t("Neste")}</span>}
        <div className="arrow-next-slide" {...buttonize(nextSlide)}>
          &darr;
        </div>
        {moreSlides && !hasClickedArrow && (
          <span className="arrow-text right">{t("gå til neste")}</span>
        )}
        {!moreSlides && (
          <span className="arrow-text right">{t("kapittel")}</span>
        )}
      </nav>
      {moreChapters && (
        <nav className="sr-only">
          <a href={pageContext.nextChapterPath}>{t("Gå til neste kapittel")}</a>
        </nav>
      )}
    </>
  )
}
