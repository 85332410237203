import { useState, useEffect } from "react"

export const useHasMounted = () => {
  const [hasMounted, setHasMounted] = useState(false)
  useEffect(() => {
    setHasMounted(true)
  }, [])
  return hasMounted
}

export const stripPrefix = (needle, haystack) => {
  if (haystack.indexOf(needle) === 0) {
    return haystack.slice(needle.length)
  }
  return haystack
}

export const stripSectionPrefixFromUri = uri => stripPrefix("kurs", uri)

export const makePath = (course, chapter, chapterIndex) => {
  let languagePart = course.courseLanguage === "en" ? "/en" : ""
  let pagePath = ""
  if (!chapter || chapterIndex === 0) {
    pagePath = stripSectionPrefixFromUri(course.uri)
  } else {
    pagePath = stripSectionPrefixFromUri(chapter.uri)
  }
  pagePath += "/"
  return languagePart + pagePath
}

export const addPathsToChapters = (chapters, course) => {
  chapters.forEach((chapter, index) => {
    chapter.path = makePath(course, chapter, index)
  })
  return chapters
}

export const addTranslatedPathToCourse = (courses, course) => {
  if (!course.translatedCourse.length === 0) {
    return
  }
  if (course.translatedCourse.length === 1) {
    const otherCourseId = course.translatedCourse[0].id
    const otherCourse = courses.find(x => x.id === otherCourseId)
    course.translatedPath = makePath(otherCourse, null, null)
  }
}

export const addTranslatedPathsToCourses = courses => {
  courses.forEach((course, index) => {
    addTranslatedPathToCourse(courses, course)
  })
}

export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const debounce = (func, wait) => {
  let timeout

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

export const buttonize = handler => {
  return {
    role: "button",
    onClick: handler,
    onKeyDown: event => {
      // 13 = Enter
      if (event.keyCode === 13) {
        handler(event)
      }
    },
  }
}
