/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import { NavigationWrapper } from "./src/components/navigation.js"

export const wrapPageElement = ({ element, props }) => {
  if (!props.pageContext.showNavigation) {
    return element
  }
  // see https://andremonteiro.pt/gatsby-i18next-wrap-page-element/
  return React.cloneElement(
    element, // I18nextProvider
    element.props,
    React.cloneElement(
      element.props.children, // I18nextContext.Provider
      element.props.children.props,
      React.createElement(
        NavigationWrapper,
        props,
        element.props.children.props.children
      )
    )
  )
}
